import React from 'react'; 
import styles from './contact.module.css'; 


const Contact = props => (
  <div className={styles.ContactWrapper}>
    
  </div>
)

export default Contact; 